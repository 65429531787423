import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/emcasa/ui/node_modules/gatsby-theme-docz/src/base/Layout.js";
import Breakpoint, { BreakpointProvider } from '@emcasa/ui-dom/components/Breakpoint';
import Row from '@emcasa/ui-dom/components/Row';
import Col from '@emcasa/ui-dom/components/Col';
import Text from '@emcasa/ui-dom/components/Text';
import View from '@emcasa/ui-dom/components/View';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "breakpoint"
    }}>{`Breakpoint`}</h2>
    <p>{`Renders children when breakpoint requirements are met`}</p>
    <Breakpoint only="phone" mdxType="Breakpoint">
  <Text mdxType="Text">Only phone</Text>
    </Breakpoint>
    <Breakpoint down="tablet" mdxType="Breakpoint">
  <Text mdxType="Text">Tablet & Phone</Text>
    </Breakpoint>
    <Breakpoint up="desktop" mdxType="Breakpoint">
  <Text mdxType="Text">Desktop</Text>
    </Breakpoint>
## BreakpointProvider
    <p>{`Returns current breakpoint`}</p>
    <BreakpointProvider mdxType="BreakpointProvider">
  {({
        breakpoint,
        isMobile
      }) => <Row mdxType="Row">
      <Col mr={2} mdxType="Col">
        <Text mdxType="Text">{breakpoint}</Text>
      </Col>
      <Col mdxType="Col">
        <Text color={isMobile ? 'pink' : 'grey'} mdxType="Text">({isMobile ? 'is mobile' : 'not mobile'})</Text>
      </Col>
    </Row>}
    </BreakpointProvider>
## withBreakpoint
    <p>{`Wraps the target component in a `}<inlineCode parentName="p">{`BreakpointProvider`}</inlineCode>{` and passes the resulting `}<inlineCode parentName="p">{`breakpoint`}</inlineCode>{` to props`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      